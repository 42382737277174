html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgb(28, 28, 28);
}

#root {
  /* display: block; */
  height: 100%;
  max-width: 450px;
}

h1 {
  margin: 0;
}

p {
  margin-left: 10px;
  padding: 0 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navbar {
  width: 100%;
  height: 50px;
  /* padding: 0 5px; */
  margin-top: 0;
  background: rgb(28, 28, 28);
  /* color: rgb(255, 136, 0); */
  color: #FFC700;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info-button {
  /* justify-self: flex-end; */
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.info-button > h1 {
  /* border: 2px solid white;
  border-radius: 100px; */
  font-size: 27px;
  /* color: white; */
}

#board {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#grid-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

#grid {
  width: 100vw;
  /* height: calc(100% - 200px); */
  /* padding: 2%; */
  /* background: lightcyan; */
  background: rgb(28, 28, 28);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.grid-row {
  /* width: 100%; */
  display: flex;
  justify-content: center;
}

.grid-cell {
  width: 40px;
  height: 40px;
  border: 2px solid rgb(255, 136, 0);
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

#keyboard {
  width: 100%;
  /* height: 25%; */
  height: 200px;
  /* position: fixed; */
  /* top: 67%; */
  background: rgb(28, 28, 28);
  color: white;
}

#enter-key, #delete-key {
  flex: 1.5;
}

.keyboard-row {
  width: 100%;
  height: 33%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  /* padding: 0px 2px; */
  align-items: center;
}

.keyboard-letter {
  /* border: 1px solid green; */
  width: 9%;
  height: 94%;
  font-size: 8px;
  margin: 0 2px;
  background-color: rgb(110, 110, 110);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guessed-letter {
  background-color: rgb(66, 66, 66);
}

.hell {
  background-color: rgb(255, 206, 116);
}

.green {
  background-color: hsl(120, 100%, 35%);
}

.yellow {
  background-color: rgb(210, 210, 0);
}

.gray {
  background-color: rgb(165, 165, 165);
}

#instructions-wrapper {
  height: 100%;
  width: 100%;
  max-width: 450px;
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  /* visibility: visible; */
  /* opacity: 1; */
  /* transition: visibility 0s linear 300ms, opacity 300ms; */
}

#instructions {
  /* height: 90%; */
  width: 90%;
  position: absolute;
  background-color: rgb(28, 28, 28);
  border-radius: 8px;
  /* z-index: 50; */
  top: 10%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  color: white;
  font-size: larger;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  /* animation-name: slidein;
  animation-duration: 0.5s;
  -webkit-animation-name: slidein;
  -webkit-animation-duration: 0.5s; */
}

#instructions > p > img {
  vertical-align: bottom;
}

#instructions > p:last-child {
  font-size: 14px;
  align-self: center;
}

#settings-wrapper {
  height: 100%;
  width: 100%;
  max-width: 450px;
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
}

#settings {
  /* height: 90%; */
  width: 90%;
  position: absolute;
  background-color: rgb(28, 28, 28);
  border-radius: 8px;
  /* z-index: 50; */
  top: 15%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  color: white;
  font-size: larger;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
}

.shown {
  transition: visibility 0s linear 150ms, opacity 200ms;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  /* z-index: -10; */
  transition: visibility 0s linear 500ms, opacity 300ms;
  /* animation-name: slideout;
  animation-duration: 0.5s;
  -webkit-animation-name: slideout;
  -webkit-animation-duration: 0.5s; */
}

.close-button {
  width: 30px;
  height: 30px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  position: absolute;
  margin-top: 5px;
  margin-right: 5px;
}

#gameover-message {
  position: absolute;
  background-color: rgb(59, 59, 59);
  /* width: 100px; */
  padding: 5px 10px;
  text-align: center;
  top: 10%;
  border-radius: 4px;
  color: white;
}

#difficulty-details {
  min-width: 85%;
  /* height: 60px; */
  border: 1px solid #FFC700;
  border-radius: 8px;
  margin-top: 50px;
}

#difficulty-details > p {
  margin-left: 0;
  padding: 0;
  text-align: center;
}

#difficulty-options {
  width: 70%;
  border: 1px solid #FFC700;
  border-radius: 8px;
  text-align: center;
  margin: 50px;
}

#difficulty-options > h1 {
  padding: 10px;
}

#difficulty-options > h1.selected {
  color: #FFC700;
}

/* #difficuly-options > h1 {
  text-align: center;
} */

/* @keyframes slidein {
  from {top: -100px; opacity: 0.5}
  to {top: 25%; opacity: 1}
}

@-webkit-keyframes slidein {
  from {top: -100px; opacity: 0.5}
  to {top: 25%; opacity: 1}
}

@keyframes slideout {
  from {top: 25%; opacity: 1}
  to {top: -100px; opacity: 0.5}
}

@-webkit-keyframes slideout {
  from {top: 25%; opacity: 1}
  to {top: -100px; opacity: 0.5}
} */